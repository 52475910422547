import { IOptionsSource, IOptionsSources } from '@shared/models/select.model';
import { AutolinkerConfig } from 'autolinker';
import { QuillModules, QuillToolbarConfig } from 'ngx-quill/lib/quill-editor.interfaces';
import { KeyValue } from '@shared/models/key-value.model';

export const AUTH_TOKEN_LS_KEY = 'AUTHORIZATION';
export const AUTH_REFRESH_TOKEN_LS_KEY = 'refresh_token';

export const SWITCH_ELEMENT_MOUSE_ENTER_TRIGGER_KEY = 'SWITCH_ELEMENT_MOUSE_ENTER_TRIGGER_KEY';

export const DATE_WITH_TIME_NO_MS_BACKEND = 'YYYY-MM-DDTHH:mm:ss';
export const DATE_WITH_TIME_BACKEND = `${DATE_WITH_TIME_NO_MS_BACKEND}.ssssss`;
export const DATE_FULL_BACKEND = 'YYYY-MM-DD';

export const DATE_FORMAT_FOR_DOWNLOAD_NAMES = 'YYYY-MM-DD_HHmmss';
export const DATE_SHORT = 'DD.MM.YY';
export const DATE_FULL = 'DD.MM.YYYY';
export const DATE_FULL_MONTH_WORD = 'DD MMMM YYYY';
export const DAY_NUMBER_AND_MONTH_WORD = 'DD MMMM';

export const DAY_NUMBER_AND_MONTH_WORD_WITH_TIME = 'DD MMMM в HH:mm';

export const DATE_FULL_WITH_TIME = 'DD.MM.YYYY HH:mm';
export const DATE_FULL_WITH_TIME_SHORT = 'DD.MM.YY HH:mm';
export const DATE_FULL_WITH_MONTH_WORD_AND_TIME = 'DD MMMM YYYY HH:mm';
export const DATE_DAY_NUMBER_MONTH_WORD_TIME = 'D MMMM, HH:mm';
export const DATE_DAY_NUMBER_MONTH = 'D MMMM';
export const MASK_DAY_MONTH_YEAR = 'd0.M0.0000';

export const MASK_HOUR_MINUTE = 'HH:mm';

export const QUILL_DEFAULT_TOOLBAR_FORMATS: QuillToolbarConfig = [
  [
    'bold',
    'italic',
    'underline',
    'strike',
    { script: 'super' },
    { script: 'sub' },
    { header: 1 },
    { header: 2 },
    'blockquote',
    'code-block',
    { color: [] },
    { background: [] },
    { list: 'ordered' },
    { list: 'bullet' },
    'direction',
    { align: [] },
    { size: [] },
    'link',
    'image',
    'clean',
  ],
];

export const QUILL_MEETING_TOOLBAR_FORMATS: QuillToolbarConfig = [
  [
    'bold',
    'italic',
    'underline',
    'strike',
    { script: 'super' },
    { script: 'sub' },
    { header: 1 },
    { header: 2 },
    { list: 'ordered' },
    { list: 'bullet' },
    { align: [] },
  ],
];

export const QUILL_MODULES_DEAFULT_SETTINGS: QuillModules = {
  history: {
    delay: 300,
    maxStack: 500,
    userOnly: true,
  },
  toolbar: QUILL_DEFAULT_TOOLBAR_FORMATS,
};

export const QUILL_MODULES_MEETING_SETTINGS: QuillModules = {
  history: {
    delay: 300,
    maxStack: 500,
    userOnly: true,
  },
  toolbar: QUILL_MEETING_TOOLBAR_FORMATS,
};

export const AUTOLINKER_CONFIG: AutolinkerConfig = {
  newWindow: true,
  urls: {
    schemeMatches: true,
    wwwMatches: true,
    tldMatches: true,
  },
  email: false,
  phone: false,
  mention: false,
  hashtag: false,
  stripPrefix: true,
  stripTrailingSlash: true,
  truncate: { length: 32, location: 'middle' },
  decodePercentEncoding: true,
  sanitizeHtml: true,
  replaceFn: (match) => {
    const tag = match.buildTag();
    tag.setClass('link link--simple');
    tag.setAttr('target', '_blank');
    tag.setAttr('rel', 'noopener noreferrer nofollow');
    return tag;
  },
};

export const BASE_OPTIONS_SOURCE: IOptionsSource = {
  limit: 20,
  keyForTransformToSelects: 'id',
  viewNameForTransformToSelects: 'name',
};

export const OPTIONS_SOURCES: IOptionsSources = {};

export const SKIP_EMIT_EVENT: KeyValue<boolean> = { emitEvent: false };

export interface ISimpleSelect<T = unknown> {
  value: T;
  label: string;
}

export interface IAction {
  label: string;
  action: Function;
  icon?: string;
}
