import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { KeyValue } from '@shared/models/key-value.model';
import { Observable } from 'rxjs';
import { ArrayPayload } from '@shared/models/payload.model';
import { IEventDto, IEventDynamicLinkDto, IEventLinkDto, IEventMember } from '@shared/interfaces/admin/event-interface';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { prepareAndDownloadFileWithServerData } from '@shared/utils/prepare-and-download-file-with-server-data';

export const EVENT_URL: string = '/events/event/';

@Injectable({
  providedIn: 'root',
})
export class EventHttpService {
  private readonly _apiPath = '/events/event/';

  constructor(private http: HttpService) {}

  getEvents(params?: KeyValue<string>, paramsString?: string): Observable<ArrayPayload<IEventDto>> {
    return this.http.get<ArrayPayload<IEventDto>>(this._apiPath, {
      params: params ? new HttpParams({ fromObject: params }) : new HttpParams({ fromString: paramsString }),
    });
  }

  getEventById(id: number): Observable<IEventDto> {
    return this.http.get<IEventDto>(`${this._apiPath}${id}/`);
  }

  getMemberEventById(id: number): Observable<IEventMember> {
    return this.http.get<IEventMember>(`/events/event-member/?state=1&event=${id}`);
  }

  getInterestingEventById(id: number): Observable<IEventMember> {
    return this.http.get<IEventMember>(`/events/event-member/?state=2&event=${id}`);
  }

  getEventLinkRecordById(id: number): Observable<IEventLinkDto> {
    return this.http.get<IEventLinkDto>(`/events/event-link/?event=${id}`);
  }

  getEventDynamicLinkById(id: number): Observable<IEventDynamicLinkDto> {
    return this.http.get<IEventDynamicLinkDto>(`${this._apiPath}${id}/dynamic-link/`);
  }

  createEventCopy(id: number): Observable<IEventDto> {
    return this.http.post<IEventDto>(`${this._apiPath}${id}/copy/`, null);
  }

  createEvent(event: Partial<IEventDto>): Observable<IEventDto> {
    return this.http.post<IEventDto>(this._apiPath, event);
  }

  updateEventById(id: number, event: Partial<IEventDto> | FormData): Observable<IEventDto> {
    return this.http.put<IEventDto>(`${this._apiPath}${id}/`, event);
  }

  patchEventById(id: number, event: Partial<IEventDto> | FormData): Observable<IEventDto> {
    return this.http.patch<IEventDto>(`${this._apiPath}${id}/`, event);
  }

  deleteEventById(id: number): Observable<void> {
    return this.http.delete<void>(`${this._apiPath}${id}/`);
  }

  createDynamicLinkForEvent(id: number): Observable<any> {
    return this.http.get(`${this._apiPath}${id}/dynamic-link/`);
  }

  // Выгрузка Бизнес/Нетворкинг
  public getEventsReportOnAttendances(): Observable<Blob> {
    return this.http
      .get<HttpResponse<Blob>>(`${this._apiPath}get-xlsx-report-on-attendances/`, {
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(map((response: HttpResponse<Blob>) => prepareAndDownloadFileWithServerData(response, 'networking')));
  }

  // Выгрузка участников мероприятия
  public getMeetingParticipants(id: number, isNeedCompanies?: boolean): Observable<Blob> {
    let params;

    if (isNeedCompanies) {
      params = new HttpParams().set('with_all_businesses', 'true');
    }

    const options = { responseType: 'blob', observe: 'response', params };

    return this.http
      .get<HttpResponse<Blob>>(`${this._apiPath}${id}/get-xlsx-report-on-members/`, options)
      .pipe(map((response: HttpResponse<Blob>) => prepareAndDownloadFileWithServerData(response, 'report')));
  }

  // Выгрузка участников мероприятия для ассистента
  public getMeetingParticipantsForAssistant(eventId: number): Observable<Blob> {
    return this.http
      .get<HttpResponse<Blob>>(`${this._apiPath}${eventId}/get-members-json-file/`, {
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(map((response: HttpResponse<Blob>) => prepareAndDownloadFileWithServerData(response, 'users')));
  }

  // Выгрузка участников мероприятия для ассистента
  public getMeetingParticipantsQrCodes(eventId: number): Observable<Blob> {
    return this.http
      .get<HttpResponse<Blob>>(`${this._apiPath}${eventId}/get-members-qrcodes-file/`, {
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(map((response: HttpResponse<Blob>) => prepareAndDownloadFileWithServerData(response, 'qr-codes')));
  }

  // Выгрузка календаря мероприятий, responseType: 'document'
  public getMeetingCalendar(form: any, params: any): Observable<void> {
    return this.http.post(`${this._apiPath}send-pdf-calendar/`, form, { params: params });
  }

  // Выгрузка календаря сопровождения
  public getMeetingCalendarSupport(meetingParams: HttpParams): Observable<Blob> {
    return this.http
      .get<HttpResponse<Blob>>(`${this._apiPath}support-event-calendar/`, {
        responseType: 'blob',
        observe: 'response',
        params: meetingParams,
      })
      .pipe(map((response: HttpResponse<Blob>) => prepareAndDownloadFileWithServerData(response, 'users')));
  }

  // Выгрузка календаря сопровождения расширенного
  public getMeetingCalendarSupportFull(meetingParams: HttpParams): Observable<Blob> {
    return this.http
      .get<HttpResponse<Blob>>(`${this._apiPath}extended-support-event-calendar`, {
        responseType: 'blob',
        observe: 'response',
        params: meetingParams,
      })
      .pipe(map((response: HttpResponse<Blob>) => prepareAndDownloadFileWithServerData(response, 'users')));
  }
}
