import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeyValue } from '@shared/models/key-value.model';
import {
  INewslinePostDto,
  INewslinePostNew,
  INewslineStory,
} from '@shared/models/newsline/dto/newsline-post-dto.model';
import { ArrayPayload } from '@shared/models/payload.model';
import { Observable } from 'rxjs';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root',
})
export class NewslinePostHttpService {
  private readonly _apiPath = '/newsline/post/';
  private readonly _apiPathStory = '/newsline/stories/';

  constructor(private http: HttpService) {}

  getPosts(params?: KeyValue<string>, paramsString?: string): Observable<ArrayPayload<INewslinePostDto>> {
    return this.http.get<ArrayPayload<INewslinePostDto>>(this._apiPath, {
      params: params ? new HttpParams({ fromObject: params }) : new HttpParams({ fromString: paramsString }),
    });
  }

  getPostById(id: number): Observable<INewslinePostDto> {
    return this.http.get<INewslinePostDto>(`${this._apiPath}${id}/`);
  }

  getPostByIdNew(id: number): Observable<INewslinePostNew> {
    return this.http.get<INewslinePostNew>(`${this._apiPath}${id}/`);
  }

  createPost(post: Partial<INewslinePostDto>): Observable<INewslinePostDto> {
    return this.http.post<INewslinePostDto>(this._apiPath, post);
  }

  updatePostById(id: number, post: Partial<INewslinePostDto>): Observable<INewslinePostDto> {
    return this.http.patch<INewslinePostDto>(`${this._apiPath}${id}/`, post);
  }

  deletePostById(id: number): Observable<void> {
    return this.http.delete<void>(`${this._apiPath}${id}/`);
  }

  likePostById(id: number): Observable<void> {
    return this.http.post<void>(`${this._apiPath}${id}/like/`, null);
  }

  unlikePostById(id: number): Observable<void> {
    return this.http.post<void>(`${this._apiPath}${id}/unlike/`, null);
  }

  // ToDo Когда появится тикет на сторис, сделать отдельный сервис
  getStoryById(id: number): Observable<INewslineStory> {
    return this.http.get<INewslineStory>(`${this._apiPathStory}${id}/`);
  }


  getExternalLinkPreview(link: string): Observable<any> {
    const api = 'https://api.linkpreview.net/?key=f7c2fd8e85020a968ec75144209fecd2&q=' + link;

    return this.http.getExternal(api);
  }
}
