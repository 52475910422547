import { Injectable } from '@angular/core';
import { ArrayPayload } from '@shared/models/payload.model';
import { BehaviorSubject, concat, forkJoin, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeMap, switchMap, take, tap, toArray } from 'rxjs/operators';
import {
  IChatMessage,
  IChatMessagesPositions,
  RetrieveMessageFile,
} from '@shared/models/messages/view/chat-message.model';
import { ChatMessageHttpService } from '@core/services/api/messages/chat-message-http.service';
import {
  IChatMessageDto,
  IMessageUploadProcessing,
  IRecipientInfo,
  RetrieveMessageFileDto, RetrieveMessageLinkDto,
} from '@shared/models/messages/dto/chat-message-dto.model';
import { ChatUtilsService } from './chat-utils.service';
import { ChatService } from './chat.service';
import { IChat } from '@shared/models/messages/view/chat.model';
import { QueryParams } from '@shared/models/query-params.model';
import { DomService } from '@core/services/business/utils/dom.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PluralPipe } from '@shared/pipes/plural.pipe';
import { ChatMessageRelatedFieldKey } from '@modules/chats/enums/keys.enum';
import { ChatMessageFileHttpService } from '@core/services/api/messages/chat-message-file-http.service';
import { MESSAGE_PLURAL } from '@shared/constants/plural.const';
import { ChatMessageFilesUploadProgressSnackbarComponent } from '@modules/chats/components/chat-message-files-upload-progress-snackbar/chat-message-files-upload-progress-snackbar.component';
import { ForwardMessagesTargetChatSelectionDialogComponent } from '@modules/chats/components/chat-list-dialog/forward-messages-target-chat-selection-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { isEmpty } from 'lodash';
import { ILocalMessageAttachment } from '@shared/models/file.model';
import { ChatLinkMetaInfoScrapingService } from '@core/services/business/messages/chat-link-meta-info-scraping.service';

const MESSAGE_SMALL_PLURAL: string[] = MESSAGE_PLURAL.map((pluralItem: string) => pluralItem.toLowerCase());
const FORWARD_MESSAGES_COUNT_SNACKBAR_DURATION: number = 2_000;
const SELECTED_MESSAGES_SELECTION_LIMIT: number = 25;

@Injectable({
  providedIn: 'root',
})
export class ChatMessageService {
  messages: IChatMessage[] = [];

  isChatMessagesLoading = false;

  public readonly updateMessageExternalTrigger$: Subject<number> = new Subject<number>();
  public readonly scrollToMessageExternalTrigger$: Subject<number> = new Subject<number>();
  public readonly newMessageReceived$: ReplaySubject<IChatMessage> = new ReplaySubject<IChatMessage>(1);

  public readonly editMessageTrigger$: Subject<IChatMessage> = new Subject<IChatMessage>();
  public readonly replyMessageTrigger$: Subject<IChatMessage> = new Subject<IChatMessage>();

  private readonly isMessagesSelectModeState$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly isMessagesSelectMode$: Observable<boolean> = this.isMessagesSelectModeState$.asObservable();
  private readonly selectedMessagesState$: BehaviorSubject<IChatMessage[]> = new BehaviorSubject<IChatMessage[]>([]);
  public readonly selectedMessages$: Observable<IChatMessage[]> = this.selectedMessagesState$.asObservable();

  private readonly _pinnedChatMessages$: BehaviorSubject<IChatMessage[]> = new BehaviorSubject<IChatMessage[]>([]);
  public readonly pinnedChatMessages$: Observable<IChatMessage[]> = this._pinnedChatMessages$.asObservable();

  private uploadProcessing: IMessageUploadProcessing | null = null;

  constructor(
    private chatMessageHttpService: ChatMessageHttpService,
    private chatService: ChatService,
    private domService: DomService,
    private chatUtilsService: ChatUtilsService,
    private chatMessageFileHttpService: ChatMessageFileHttpService,
    private snackBar: MatSnackBar,
    private pluralPipe: PluralPipe,
    private readonly dialog: MatDialog,
    private chatLinkMetaInfoScrapingService: ChatLinkMetaInfoScrapingService
  ) {
    this.chatService.currentChat$
      .pipe(
        distinctUntilChanged((chat, chatOld) => chat?.id === chatOld?.id),
        filter((chat) => Boolean(chat)),
        switchMap((currentChat) => this.updatePinnedMessage$(currentChat)),
      )
      .subscribe();
  }

  public updatePinnedMessage() {
    this.updatePinnedMessage$(this.currentChat).pipe(take(1)).subscribe();
  }

  private updatePinnedMessage$(currentChat: IChat) {
    return this.getChatMessages(currentChat.id, { is_pinned: 'true' }).pipe(
      tap(({ results }) => {
        this._pinnedChatMessages$.next(results);
      }),
    );
  }

  public clearPinnedMessages() {
    this._pinnedChatMessages$.next([]);
  }

  public setIsMessageSelectModeState(isForwardMode: boolean): void {
    this.isMessagesSelectModeState$.next(isForwardMode);
  }

  public setSelectedMessagesState(selectedMessages: IChatMessage[]): void {
    this.selectedMessagesState$.next(selectedMessages);
  }

  public setDeletedMessagesCountState(count: number): void {
    if (count) {
      this.snackBar.open(`Удалено ${count} ${this.pluralPipe.transform(count, MESSAGE_SMALL_PLURAL)}`, 'Закрыть', {
        duration: FORWARD_MESSAGES_COUNT_SNACKBAR_DURATION,
        panelClass: 'snack-bar-container-custom',
      });
    }
  }

  public getChatMessages(chatId: number, params: QueryParams): Observable<ArrayPayload<IChatMessage>> {
    return this.chatMessageHttpService.getChatMessages(chatId, params).pipe(
      take(1),
      map((response: ArrayPayload<IChatMessageDto>) => {
        return {
          results: response.results.map((chatMessage: IChatMessageDto) =>
            this.chatUtilsService.transformMessageDtoToMessageView(chatMessage),
          ),
        };
      }),
    );
  }

  public getChatMessageParticipantsInfo(
    chatId: number,
    messageId: number,
    params: QueryParams,
  ): Observable<ArrayPayload<IRecipientInfo>> {
    return this.chatMessageHttpService.getChatMessageParticipantsInfo(chatId, messageId, params);
  }

  public getChatMessageById(messageId: number, chatId: number): Observable<IChatMessage> {
    return this.chatMessageHttpService
      .getChatMessageById(chatId, messageId, { fields: 'author' })
      .pipe(
        map((rawChatMessage: IChatMessageDto) =>
          this.chatUtilsService.transformMessageDtoToMessageView(rawChatMessage),
        ),
      );
  }

  public initOnNewChatOpened(): void {
    this.messages = [];
    this.isChatMessagesLoading = true;

    // Очистка выбранных сообщений
    this.setIsMessageSelectModeState(false);
    this.resetSelectionOfSelectedMessages();

    // Автофокус в поле нового сообщения
    if (this.domService.getElementById('messageInputField')) {
      this.domService.getElementById('messageInputField').focus();
    }
  }

  private createChatMessage(chatId: number, chatMessage: Partial<IChatMessageDto>): Observable<IChatMessage> {
    return this.chatMessageHttpService
      .createChatMessage(chatId, chatMessage)
      .pipe(
        map((rawChatMessage: IChatMessageDto) =>
          this.chatUtilsService.transformMessageDtoToMessageView(rawChatMessage),
        ),
      );
  }

  public selectMessage(message?: IChatMessage): void {
    this.setIsMessageSelectModeState(true);

    if (message) {
      message.isSelected = true;
      this.selectedMessages$
        .pipe(take(1))
        .subscribe((selectedMessages: IChatMessage[]) => this.setSelectedMessagesState([...selectedMessages, message]));
    }
  }

  //-----------------------------START ADDING FILE AND LINKS TO MESSAGE-----------------------------------//

  public createChatMessageWithAttachmentsAndAddToMessages(
    chatId: number,
    chatMessage: Partial<IChatMessageDto>,
    files?: ILocalMessageAttachment[],
    links?: string[]
  ): Observable<IChatMessage> {
    const attachmentsRequests: Observable<RetrieveMessageFile>[] = this._getCreateRequestsForPostAttachments(
      chatId,
      files,
    );

    let linksObservable: Observable<RetrieveMessageLinkDto[]> = of(null); // По умолчанию пустой observable, если ссылок нет.

    if (links && links.length) {
      linksObservable = this.chatLinkMetaInfoScrapingService.scrapLinkMetaInfo(links).pipe(
        tap((linksWithMetaInfo: RetrieveMessageLinkDto[]): void => {
          chatMessage.links = linksWithMetaInfo;
        })
      );
    }

    if (attachmentsRequests.length) {
      this.uploadProcessing = {
        done: new BehaviorSubject<number>(0),
        total: attachmentsRequests.length,
      };

      this.snackBar.openFromComponent(ChatMessageFilesUploadProgressSnackbarComponent, {
        data: this.uploadProcessing,
      });

      const attachmentsObservable = concat(...attachmentsRequests).pipe(
        tap((createdAttachment: RetrieveMessageFile) => {
          if (!chatMessage.files) {
            chatMessage.files = [];
          }
          chatMessage.files.push(createdAttachment.id);
        }),
        toArray() // Ждем завершения всех запросов на создание файлов
      );

      // Объединяем потоки ссылок и файлов
      return forkJoin([linksObservable, attachmentsObservable]).pipe(
        mergeMap(() => {
          this.snackBar.dismiss();
          this.snackBar.open('Загрузка файлов завершена', null, { duration: 1000 });
          this.uploadProcessing = null;

          // После загрузки файлов и обработки ссылок создаем сообщение
          return this.createChatMessage(chatId, chatMessage);
        })
      );
    }

    // Если нет файлов, просто обрабатываем ссылки и создаем сообщение
    return linksObservable.pipe(
      mergeMap(() => this.createChatMessage(chatId, chatMessage))
    );
  }


  public postLinksBulk(chatId: number, messageId: number, links: string[]): Observable<void> {
    return this.chatMessageFileHttpService.postLinksBulk(chatId, messageId, links);
  }

  private _getCreateRequestsForPostAttachments(
    chatId: number,
    attachments?: ILocalMessageAttachment[],
  ): Observable<RetrieveMessageFile>[] {
    if (!attachments || isEmpty(attachments)) {
      return [];
    }

    return attachments.map((attachment: ILocalMessageAttachment, index: number) => {
      attachment.position_in_msg = index + 1;

      return this._getCreateRequestForPostFile(attachment, chatId);
    });
  }

  private _getCreateRequestForPostFile(
    fileDto: Partial<ILocalMessageAttachment>,
    chatId?: number,
  ): Observable<RetrieveMessageFile> {
    return this.chatMessageFileHttpService.createFile(fileDto, chatId).pipe(
      tap(() => {
        if (this.uploadProcessing) {
          this.uploadProcessing.done.next(this.uploadProcessing.done.value + 1);
        }
      }),
    );
  }

  //-----------------------------END ADDING FILE AND LINKS TO MESSAGE-----------------------------------//

  public patchChatMessageById(
    chatId: number,
    messageId: number,
    chatMessage: Partial<IChatMessageDto> | FormData,
  ): Observable<IChatMessage> {

    return this.chatMessageHttpService
      .patchChatMessageById(chatId, messageId, chatMessage)
      .pipe(
        map((rawChatMessage: IChatMessageDto) =>
          this.chatUtilsService.transformMessageDtoToMessageView(rawChatMessage),
        ),
      );
  }

  public openForwardMessagesDialog() {
    this.selectedMessages$.pipe(take(1)).subscribe((selectedMessages: IChatMessage[]) => {
      const isSelectLimitAchieved: boolean = selectedMessages.length > SELECTED_MESSAGES_SELECTION_LIMIT;

      if (isSelectLimitAchieved) {
        this.snackBar.open(
          `Вы не можете переслать больше ${SELECTED_MESSAGES_SELECTION_LIMIT} сообщений. Сейчас выбрано ${selectedMessages.length}`,
          'Закрыть',
          {
            duration: FORWARD_MESSAGES_COUNT_SNACKBAR_DURATION,
            panelClass: 'snack-bar-container-custom',
          },
        );
        return;
      }

      this.dialog.open(ForwardMessagesTargetChatSelectionDialogComponent);
    });
  }

  private _deleteChatMessageById(
    chatId: number,
    messageIds: number[] | number,
    isDeleteForAll?: boolean,
  ): Observable<void> {
    return this.chatMessageHttpService.deleteChatMessageById(chatId, messageIds, isDeleteForAll);
  }

  public deleteChatMessageByIdForCurrentUser(chatId: number, messageIds: number[] | number): Observable<void> {
    return this._deleteChatMessageById(chatId, messageIds);
  }

  public deleteChatMessageByIdForAllUsers(chatId: number, messageId: number[] | number): Observable<void> {
    return this._deleteChatMessageById(chatId, messageId, true);
  }

  // Сбрасывает все выбранные сообщения при отмене пересылки
  public resetSelectionOfSelectedMessages(): void {
    this.selectedMessages$.pipe(take(1)).subscribe((selectedMessages: IChatMessage[]) => {
      selectedMessages.forEach((message: IChatMessage) => (message[ChatMessageRelatedFieldKey.IsSelected] = false));
      this.setSelectedMessagesState([]);
    });
  }

  // Позиция сообщения
  public getMessagesPosition(chatId: number, messageId: number): Observable<IChatMessagesPositions> {
    if (chatId && messageId) {
      return this.chatMessageHttpService.getMessagesPosition(chatId, { messages_id: messageId.toString() });
    }
    return of();
  }

  // Прочитать все сообщения до отправленного
  public readMessagesUntilMessageId(chatId: number, messageId: number): Observable<number> {
    return this.chatMessageHttpService.readChatMessagesUntilMessageId(chatId, messageId);
  }

  public readAllChatMessages(): Observable<void> {
    return this.chatService.currentChat$.pipe(
      take(1),
      map((currentChat: IChat) => currentChat.id),
      filter(() => !!this.messages.length),
      switchMap((currentChatId: number) => this.chatService.readAllChatMessagesByChatId(currentChatId)),
      tap(() => this.chatService.setCurrentChatUnreadCounterState(0)),
    );
  }

  // ToDo: Refactor
  get currentChat(): IChat {
    return this.chatService.currentChatState$$.value;
  }
}
