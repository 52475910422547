import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

type HttpOptionsValueType = string | boolean | HttpParams | HttpHeaders;

const DEFAULT_OPTIONS: { [key: string]: HttpOptionsValueType } = {
  responseType: 'json',
};

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  private _currentHost: string;

  constructor(private httpClient: HttpClient) {
    this._currentHost = location.origin;
  }

  get<T>(
    path: string,
    options?: { [key: string]: HttpOptionsValueType },
    url = environment.apiUrl,
    apiVersionPath = environment.apiVersionPath,
  ): Observable<T> {
    return this.httpClient.get(
      (url ?? this._currentHost) + (apiVersionPath ?? '') + path,
      options ?? {},
    ) as Observable<T>;
  }

  getExternal<T>(
    path: string,
  ): Observable<T> {
    return this.httpClient.get(path) as Observable<T>;
  }

  post<T>(
    path: string,
    body: any,
    options?: { [key: string]: HttpOptionsValueType | HttpParams },
    url = environment.apiUrl,
    apiVersionPath = environment.apiVersionPath,
  ): Observable<T> {
    return this.httpClient.post((url ?? this._currentHost) + (apiVersionPath ?? '') + path, body, {
      ...DEFAULT_OPTIONS,
      ...options,
    }) as Observable<T>;
  }

  put<T>(
    path: string,
    body: any,
    options?: { [key: string]: HttpOptionsValueType },
    url = environment.apiUrl,
    apiVersionPath = environment.apiVersionPath,
  ): Observable<T> {
    return this.httpClient.put((url ?? this._currentHost) + (apiVersionPath ?? '') + path, body, {
      ...DEFAULT_OPTIONS,
      ...options,
    }) as Observable<T>;
  }

  patch<T>(
    path: string,
    body: any,
    options?: { [key: string]: HttpOptionsValueType },
    url = environment.apiUrl,
    apiVersionPath = environment.apiVersionPath,
  ): Observable<T> {
    return this.httpClient.patch((url ?? this._currentHost) + (apiVersionPath ?? '') + path, body, {
      ...DEFAULT_OPTIONS,
      ...options,
    }) as Observable<T>;
  }

  delete<T>(
    path: string,
    options?: { [key: string]: HttpOptionsValueType },
    url = environment.apiUrl,
    apiVersionPath = environment.apiVersionPath,
  ): Observable<T> {
    return this.httpClient.delete((url ?? this._currentHost) + (apiVersionPath ?? '') + path, options) as Observable<T>;
  }
}
