import { Injectable } from '@angular/core';
import { KeyValue } from '@shared/models/key-value.model';
import { ISelectOption } from '@shared/models/select.model';
import { v4 as uuidv4 } from 'uuid';
import Autolinker, { Match } from 'autolinker';
import { AUTOLINKER_CONFIG } from '@shared/constants/common.const';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  // Инициализация автолинкера.
  private readonly _autolinker = new Autolinker(AUTOLINKER_CONFIG);

  /**
   * Удаляет `nullable элементы` из объекта.
   *
   * **TODO**: расширить метод на обработку вложенных массивов
   */
  removeEmptyElementsFromObjectDeep<T>(object: T): T {
    if (object) {
      return Object.entries(object)
        .filter(([, v]) => v !== null && v !== undefined && v !== '')
        .reduce(
          (acc, [k, v]) => ({
            ...acc,
            [k]: v === Object(v) && !Array.isArray(v) ? this.removeEmptyElementsFromObjectDeep(v) : v,
          }),
          {} as T,
        );
    }
  }

  /**
   * Трансформирует переданный массив в опции для селекта.
   *
   * @param objectsArray Массив для трансформации.
   * @param viewName Название поля в переданных обхектах, которое нужно записать в поле `viewName` опции.
   * @param key Название поля в переданных обхектах, которое нужно записать в поле `key` опции.
   * @returns Искомый массив опций.
   */
  transformObjectArraysToSeletOptions(
    objectsArray: Array<KeyValue<any>>,
    viewName: string,
    key: string,
  ): ISelectOption[] {
    return objectsArray?.map(
      (object) =>
        ({
          viewName: object[viewName],
          key: object[key],
          data: object,
        } as ISelectOption),
    );
  }

  /**
   * Трансформирует переданный объект в форм-дату.
   */
  transformObjectToFormData(object: KeyValue<any>): FormData {
    const fd = new FormData();

    if (object) {
      Object.entries(object).forEach(([key, value]) => {
        fd.append(key, value);
      });
    }

    return fd;
  }

  /**
   *
   * @returns Уникальный ID
   */
  getUniqueId(): string {
    return uuidv4();
  }

  /**
   * В месте вызова "ставит код на паузу" на переданное кол-во миллисекунд.
   *
   * Пример:
   *
   * `await sleep(500)`
   */
  sleep(ms: number): Promise<unknown> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  /**
   * Возвращает массив ссылок обнаруженных в строке.
   */
  getLinksFromString(target: string): Match[] {
    if (!target) {
      return [];
    }

    return this._autolinker.parse(target);
  }

  /**
   * Возвращает строку обернув обнаруженные ссылки в тег <a>.
   */
  transformLinksInStringToHtml(target: string): string {
    if (!target) {
      return '';
    }

    const urlPattern = /(https?:\/\/[^\s]+)/g;
    let matches = target.match(urlPattern);

    if (matches) {
      matches.forEach(link => {
        if (link.includes('story')) {
          target = target.replace(link, '');
        }
      });
    }

    return this._autolinker.link(target);
  }
}
